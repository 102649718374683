/* Global */

:root {
	--background: #4f5f4e;
	--foreground: #a6b6ac;
}

@font-face {
	font-family: "cardinal";
	src: url("../public/fonts/cardinalclassicshortweb-mediumitalic-TRIAL.woff");
	font-weight: normal;
	font-style: italic;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

@font-face {
	font-family: "bobby";
	src: url("../public/fonts/SebastianBobby.woff");
	font-weight: normal;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	background-color: var(--background);
	color: var(--foreground);
	font-size: 12px;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.App {
	margin: 2rem 0;
}

a {
	color: unset;
}

a.no-line {
  text-decoration: none;
}

/* Typography */

body {
	font-size: 1.5rem;
	line-height: 1.4em;
}

.text-body,
button {
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.text-italic {
	font-family: "cardinal", serif;
	font-weight: normal;
	font-style: italic;
	font-size: 4rem;
	letter-spacing: -0.02em;
	line-height: 1em;
}

.text-small {
	font-size: 1rem;
	line-height: 1.4em;
}

.text-lower {
	text-transform: none;
}

.text-super {
	text-transform: none;
	font-size: 2rem;
	line-height: 1em;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

/* Text Container */

.text-container > * {
	margin: 1rem 0;
}

.text-container > *:first-child {
	margin-top: 0;
}

.text-container > *:last-child {
	margin-bottom: 0;
}

.text-paragraph {
	margin-top: 2.0rem;
}

.box-container {
	border: solid 1px var(--foreground);
}

@media (max-width: 640px) {
	body {
		font-size: 1.2rem;
		line-height: 1.4em;
	}
	.text-italic {
		font-size: 3rem;
		line-height: 1em;
	}
}

/* Main Component */

main,
.main-container {
	width: 90%;
	max-width: 60ch;
	margin: 0 auto;
	background-color: var(--background);
	border-left: solid 1px var(--foreground);
	border-right: solid 1px var(--foreground);
}

.main-inner {
	width: 80%;
	margin: auto;
}

@media (max-width: 640px) {
	.main-inner {
		width: 90%;
	}
}

@media (max-width: 320px) {
	main,
	.main-container {
		width: 95%;
	}
	.main-inner {
		width: 98%;
	}
}

.row-top-rem {
	padding-top: 1rem;
}

/* Row Component */

.row-top {
	padding-top: 4rem;
}

.row-bottom-rem {
	padding-bottom: 1rem;
}

.row-bottom {
	padding-bottom: 4rem;
}

/* Navigation Component */

.header-container {
	display: flex;
	justify-content: center;
}

.header-inner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 1rem;
	row-gap: 0;
}

.header-container button {
	background-color: unset;
	border: none;
	padding: 0.5rem 0;
	color: var(--foreground);
	position: relative;
}

.header-container button:after {
	content: "";
	position: absolute;
	height: 1px;
	width: 0%;
	left: 50%;
	bottom: 0.4rem;
	transform: translateX(-50%);
	background-color: var(--foreground);
	transition: width 0.3s ease-in;
}

.header-container button:hover {
	cursor: pointer;
}

.header-container button:hover:after {
	width: 90%;
}

/* Image Component */

.image-container {
	padding: 0 0.5rem;
	line-height: 0;
}

img {
	max-width: 100%;
	height: auto;
}

/* Box Component */

.box-container {
	padding: 1rem;
}

.line-top {
	border-top: solid 1px var(--foreground);
	padding-top: 1.5rem;
}

.line-bottom {
	border-bottom: solid 1px var(--foreground);
	padding-bottom: 1.5rem;
}

/* Form Component */

input, textarea {
	display: block;
	margin: 1rem 0;
	padding: 1rem;
	width: 100%;
	color: var(--foreground);
	background-color: rgba(0, 0, 0, 0);
	border: solid 1px var(--foreground);
}

/* RSVP Form */

.rsvp-form input {
	text-align: center;
	font-family: "bobby", serif;
	font-weight: normal;
	font-style: normal;
	font-size: 6rem;
	letter-spacing: 0;
	line-height: 1em;
	padding: 2rem 0.5rem;
}

.rsvp-row {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	border: 1px solid var(--foreground);
	padding: 1rem;
}

.rsvp-question {
	text-align: left;
}

.rsvp-answer {
	display: flex;
	align-items: flex-start;
	gap: 1rem;
}

.rsvp-answer label {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.rsvp-answer input {
	margin: 0;
}

/* Illustration Component */

.illustration {
	fill: var(--foreground);
}

#castle-svg {
	width: 60%;
	margin: auto;
}

#line-svg {
	width: 40%;
}

/* Puzzle Inputs */

.puzzle-error {
	margin-bottom: 1rem;
}

.puzzle-container {
	margin: 0 1rem;
}

.puzzle-container input {
	margin: 0;
}

.puzzle-container span {
	font-size: 0.75rem;
	letter-spacing: 0;
}

.puzzle-counter {
	display: flex;
	justify-content: center;
	gap: 1rem;
}

button:hover {
	cursor: pointer;
}

button.cta:disabled:hover {
  cursor: default;
}

a.cta,
button.cta {
	padding: 0.5rem 1rem;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid var(--foreground);
	color: var(--foreground);
	font-size: 1.5rem;
	transition: background-color 0.3s ease-in, color 0.3s ease-in;
}

a.cta:hover,
button.cta:enabled:hover {
	background-color: var(--foreground);
	border: 1px solid var(--foreground);
	color: var(--background);
}

button.cta:disabled,
button[disabled]{
	background-color: #bababa;
	border: 1px solid var(--foreground);
  color: var(--background);
}

@media (max-width: 640px) {
	.puzzle-container span {
		font-size: 0.5rem;
	}
}

/* Toggle */

.toggle-container {
	margin: 1.5rem 1.5rem;
}

.toggle {
	border: 1px solid var(--foreground);
}

.toggle-title {
	display: block;
	border: none;
	color: var(--foreground);
	background-color: unset;
	padding: 1rem 4rem 1rem 1rem;
	font-size: unset;
	width: 100%;
	text-align: left;
	position: relative;
	transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.toggle-title:after {
	content: "+";
	position: absolute;
	right: 1rem;
	top: 1rem;
}

.toggle.open .toggle-title:after {
	content: "–";
}

.toggle-title:hover {
	color: var(--background);
	background-color: var(--foreground);
}

.toggle-content {
	display: none;
	border-top: 1px solid var(--foreground);
}

.toggle.open .toggle-content {
	display: block;
}

.toggle-content .text-container {
	padding: 1rem;
}

.toggle-row {
	display: flex;
	padding: 0;
}

.toggle-row .row-item {
	padding: 1rem;
	width: 50%;
}

.toggle-row p.row-item,
.toggle-row a.row-item {
	border-right: 1px solid var(--foreground);
}

.toggle-row .row-item .item-link {
	display: block;
	padding: 1rem 0 0;
}

.toggle-row .row-item .item-link:after {
	content: "→";
	margin-left: 0.5rem;
}

@media (max-width: 640px) {
	.toggle-row {
		flex-direction: column;
	}
	.toggle-row .row-item {
		width: 100%;
	}
	.toggle-row a.row-item {
		border-width: 0;
		padding: 1rem 1rem 0 1rem;
	}
}

/* App Pre-exist */

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}